/* FOOTER CSS */
.page-footer
{
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: white;
    border-top: gainsboro 1px solid;
}

.footer-link
{
    color: #4b8e99;
    font-size: x-large;
    text-decoration: underline;
}

.row-footer
{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.page-footer > .row-footer
{
    margin-right: 0;
    margin-left: 0;
}

.page-footer > .row-footer > div
{
    min-width: fit-content;
}

@media (max-width: 808px)
{
    .row-footer
    {
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: center;
    }

    .row-footer > div.col-2
    {
        margin-top: 2em;
    }
}

@media (min-width: 809px)
{
    .row-footer > .user-help-link
    {
        text-align: right;
        align-self: center;
    }

    .row-footer > .footer-links
    {
        margin-left: auto;
    }
}

@media (max-width: 992px) {
    .img-responsive {
        width: 80%;
    }
}

@media (max-width: 575px) {
    .img-responsive {
        width: 8em;
    }
}

.footer-copyright {
    font-size: 1.2rem;
}