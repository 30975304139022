.progressbar-container {
    z-index: 1;
}

.progressbarBg {
    background-color: white;
    z-index: 0;
}

@media  (max-width: 499px) {
    ul {
        padding: 0;
        margin-top: 2em;
        height: fit-content;
    }
    .progressbar {
        margin-top: 57px;
    }

    .progressbar li {
        list-style-type: none;
        position: relative;
        text-transform: uppercase;
        color: #498a98;
        border-left: .3em solid #498a98;
        height: 5em;
        padding-left: 36px;
        margin-left: 29px;
        font-size: .95em;
        padding-top: 7px;
        z-index: 0;
    }

    .progressbar li:before {
        position: absolute;
        left: -28px;
        top: -7px;
        font: normal normal normal 14px/1 FontAwesome;
        width: 50px;
        height: 50px;
        text-align: center;
        font-family: FontAwesome;
        line-height: 48px;
        font-size: 26px;
        border: 2px solid #498a98;
        margin: 0 auto 11px;
        border-radius: 50%;
        background-color: #fff;
        z-index: 0;
    }

    ul li:last-child {
        border-left: 0.3em solid transparent !important;
    }

    li.active{
        color: #cbdde1;
        border-left: .3em solid #cbdde1;
    }

    li.active ~ li{
        color: #498a98;
        border-left: .3em solid #cbdde1;
    }

    .fa-check {
        margin-left: -25px;
        position: absolute;
        z-index: 1;
        margin-top: 14px;
    }

    .fa-2x {
        font-size: 1.4em;
    }
}

@media (min-width: 500px) {
    .progressbar {
        margin: 0;
        padding: 0;
        counter-reset: step;
        margin-top: 3em;
        margin-bottom: 2em;
        height: fit-content;
        display: flex;
    }
    .progressbar li {
        list-style-type: none;
        width: 33%;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: #498a98;
    }

    .progressbar li:before {
        font: normal normal normal 14px/1 FontAwesome;
        width: 70px;
        height: 70px;
        font-family: FontAwesome;
        font-size: 3em;
        line-height: 65px;
        counter-increment: step;
        border: 2px solid #498a98;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: white;
    }

    .progressbar li:after {
        width: 100%;
        height: 3px;
        content: '';
        position: absolute;
        background-color: #498a98;
        top: 33px;
        left: -50%;
        z-index: -1;
    }

    .progressbar li.fa-envelope-o:after {
        content: none;
    }

    .progressbar li:first-child:after {
        content: none;
    }

    .progressbar li.active {
        color: #498a98;
    }

    .progressbar li.active:before {
        border-color: #498a98;
    }

    .progressbar li.active:after {
        color: #498a98;
        background-color: #498a98;
    }

    .progressbar li.active + li:after {
        background-color: #cbdde1;
    }

    .progressbar li.active~li:after {
        background-color: #cbdde1;
    }

    .progressbar li.active~li {
        color: #bebebe;
    }

    .progressbar li.active~li:before {
        border-color: #cbdde1;
    }

    .fa-check {
        margin-left: 21px;
        position: absolute;
        z-index: 1;
        margin-top: -39px;
    }

    .fa-2x {
        font-size: 2em;
    }
}

.progressbar li:first-child:after {
    content: none;
}

.progressbar li.active {
    color: #498a98;
}
.progressbar li.active:before {
    border-color: #498a98;
}

.progressbar li.active:after {
    color: #498a98;
    background-color: #498a98;
}

.progressbar li.active + li:after {
    background-color: #cbdde1;
}

.progressbar li.active~li:after {
    background-color: #cbdde1;
}

.progressbar li.active~li {
    color: #bebebe;
}

.progressbar li.active~li:before {
    border-color: #cbdde1;
}

.fa-check {
    color:#00bb00;;
}
