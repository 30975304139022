@import "~jquery-ui/themes/base/all.css";
@import "~font-awesome/css/font-awesome.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import "~select2/dist/css/select2.css";
@import "footer.css";
@import "progressbar.css";

html, body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

body {
    overflow-x: hidden;
}

#page-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100%;
    background-color: #F0F0F0;
}

#body-container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    z-index: 0;
    background-color: #F0F0F0;
}

.fos_user_resetting_request {
    margin-top: 5em;
}

.card-button, .linkButton, .titleRequest {
    background: #498b97;
    border: none;
    box-shadow: 0px 1px 5px 0px #a7a7a7;
    color: white;
}

.card-button:hover, .titleRequest:hover {
    background-color: #407580;
    color: white;
}

.card-button:active {
    color: white;
    box-shadow: 0 5px gray;
    transform: translateY(2px);
}

a.btn.card-buttonCancel {
    cursor: pointer;
}

.card-buttonBack, .titleResponse, a.btn.card-buttonCancel {
    background: darkgrey;
    border: grey;
    color: white;
}

.card-buttonBack:hover, button.titleResponse:hover, a.btn.card-buttonCancel:hover {
    background-color: grey;
    color: white;
}

.historyContainer .titleRequest, .historyContainer .titleResponse {
    font-weight: bold;
}

.titleResponse, .titleRequest {
    border-radius: 0px;
}

.linkButton {
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 2em;
}

.linkButton:hover {   
    background-color: #407580;
}

.linkButton span {
    font-weight: bold;
    color: white;
}

#sf_admin_flashes {
    text-align: center;
    font-size: 110%;
    z-index: 999;
}

.topfixed {
    position:fixed;
    top:0;
    left:0;
    width:100%;
}

/*User Messages*/
.error, .alert, .notice, .success, .info {padding:0.8em;border:2px solid #ddd;}
.error, .alert {background:#fbe3e4;color:#8a1f11;border-color:#fbc2c4;}
.notice {background:#fff6bf;color:#514721;border-color:#ffd324;}
.success {background:#e6efc2;color:#264409;border-color:#c6d880;}
.info {background:#d5edf8;color:#205791;border-color:#92cae4;}
.error a, .alert a {color:#8a1f11;}
.notice a {color:#514721;}
.success a {color:#264409;}
.info a {color:#205791;}

.reset-password-form {
    margin-top: 12%;
}

.header {
    color: #498c98;
    margin-top: 3em;
    margin-bottom: 3em;
}

.choiceInput label {
    margin-left: 5px;
}

.custom-control-inline {
    margin-right: 0px;
}

@media (max-width: 480px) {
    .newProcessDescription {
        padding: 10px;
    }
}

@media (max-width: 600px) {
    .header > .header-title {
        font-size: 150%;
    }

    .header > .header-introduction {
        font-size: 100%;
    }

    div[id="response_to_details_request_requestAccepted"], div[id="response_to_cessation_request_cessationAccepted"]{
        display: inline-block;
        text-align: left;
    }
}

@media  (min-width: 601px) {
    .header > .header-title {
        font-size: 250%;
    }

    .header > .header-introduction {
        font-size: 115%;
    }

    .choiceInput {
        display: inline;
        margin:6px;
    }
}

@media  (min-width: 1250px) {
    .header > .header-title {
        font-size: 330%;
    }

    .header > .header-introduction {
        font-size: 130%;
    }
}

.form-choice-type:not(:first-child) {
    margin-left: 13px;
}

.custom-form-error {
    color: red;
    font-size:106.25%;
}

.request-accepted {
    color:green;
}

.request-rejected {
    color:red;
}

.custom-table-header, .breadcrumbNavigationBg {
    background-color: #498b98;
    color: white;
}

.link-disabled {
    pointer-events: none;
    cursor: default;
}

.customInput {
    position: relative;
    border: 0;
    height: 35px;
    font-size: 100%;
    border-bottom: 2px solid #c8ccd4;
    bottom:10px;
}

.form-control:focus {
    box-shadow: none;
}

.accordian-child-header {
    margin-left: -22px;
    margin-right: -22px;
    margin-top: -19px;
}

.backButtonNewProcessFormPage {
    margin-top: 16px;
    margin-bottom: 16px;
}

.fa-times {
    margin-right: 5px;
    color: red;
}

.card, form:not([name="process"]) .card:last-of-type {
    box-shadow: 4px 7px 16px -7px rgba(0, 0, 0, 0.15), -4px 0px 16px -7px rgba(0, 0, 0, 0.15);
}

.card, form:not([name="process"]) .card {
    box-shadow: 4px 0 16px -7px rgba(0, 0, 0, 0.15), -4px 0 16px -7px rgba(0, 0, 0, 0.15);
}

form[name="process"] .card {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
}

.card.custom-card .card-body {
    padding: 0 20px 0 20px;
}

.card.custom-card {
    color: #757575;
}

.responsePending {
    color: #004085;
}

.responseAccepted {
    color: #155724;
}

.responseRejected {
    color: #A34648;
}

.card.custom-card label {
    font-weight: bold;
}

.card.custom-card form .form-group label {
    font-weight: normal;
}

form .card.custom-card label {
    font-weight: normal;
}

.pageTitle, h3.card-header {
    color: #498b98;
}

#validation-error label {
    display: inline;
}

.select-field-validation-error {
    position: absolute;
    margin-top: 35px;
}

@media (max-width: 380px) {
    .g-recaptcha {
        transform: scale(0.85);
        transform-origin: 0 0;
    }
}

ul.breadcrumbNavigation li h1 {
    display: inline-block;
    font-size: 106.25%;
    margin-bottom: 0px;
}

.breadcrumbNavigation li h1 {
    font-weight: bold;
}

ul.breadcrumbNavigation li {
    display: inline;
    color: white;
}

ul.breadcrumbNavigation li h1 a.process-state-link {
    color:#DAF0F5;
    text-decoration: underline;
}

ul.breadcrumbNavigation li h1 a {
    color:white;
}

ul.breadcrumbNavigation li+li:before {
    padding: 8px;
    color: white;
    content: "/\00a0";
}

.dateAndTimeSizeSmall {
    font-size: 81.25%;
    margin: 10px;
}

.custom-file-label {
    overflow: hidden;
}

.custom-file-label::after {
    font-family: 'FontAwesome' !important;
    content: "\f093" !important;
    color: #495057 !important;
}

.customFileDescription {
    position: initial;
    border: 0;
    height: 35px;
    font-size: 100%;
    border-bottom: 2px solid #c8ccd4;
    bottom:10px;
}

.btn-remove-file {
    background-color: #ff7474;
    color: white;
}

.btn-remove-file:hover {
    background-color: red;
    color: white;
}

label.required:after {
    content: " *";
    color: red
}

label.required-choice:after {
    content: "(*)";
    color: red
}

label.terms-and-conditions, label.personal-information, label.platform-purpose {
    padding: 3px;
}

input.terms-and-conditions, input.personal-information, input.platform-purpose {
    width: 20px;
    height: 20px;
}

.title-bar {
    min-height: 98px;
    width: 100%;
}

.process-state {
    font-size: 130%;
}

.help-text {
    font-size: 80%;
    font-style: italic;
    padding-left: 6px;
}

p.help-text::before {
    font-family: 'FontAwesome';
    content: "\f05a";
    font-style: normal;
    padding-right: 3px;
}

.language_switcher {
    color: #498b97;
    font-size: 75%;
    text-align: right;
    margin-right: 16px;
    margin-top: 8px;
    list-style: none;
}

.user-support-accordion > .card {
    box-shadow: none;
    border: none;
}

.user-support-accordion > .card a {
    text-decoration: none;
}

.user-support-accordion > .card .card-header .fa {
    transition: .3s transform ease-in-out;
}

.user-support-accordion > .card .card-header .collapsed .fa {
    transform: rotate(180deg);
}

div.process-state h1 {
    font-size: 150%;
}

.label-static {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.disclaimer {
    font-size: 81.25%;
    font-weight: bold;
}

div.modal-header.modal-custom {
    background-color: #498b98;
}

.auth-button {
    padding: 2px 15px;
    font-size: 81%;
    font-weight: lighter;
}

.auth-button img {
    height: 25px;
}

.cc-cmd-fix-align {
    position: relative;
    bottom: 10px;
}

.disclaimer-strong {
    font-weight: bold;
    font-style: italic;
    font-size: smaller;
}

.disclaimer-strong:before {
    content: "*";
}

.disclaimer-strong img {
    height: 21px;
}

button#cc-cmd-btn {
    opacity: 1;
    background-color: #ebedf0;
}


button#cc-cmd-btn:hover {
    background-color: #d2d6dc;
}

button#cc-cmd-btn:disabled {
    opacity: 0.3;
}

button#cc-cmd-btn:disabled:hover {
    opacity: 0.3;
    background-color: #ebedf0;
}

a#change-data-btn {
    background-color: #ebedf0;
}

a#change-data-btn:hover {
    background-color: #d3d6db;
}

.cc-error {
    font-size: 99% !important;
}

.hidden {
    display: none;
}

.single-inline-checkbox {
    margin-left: 5px;
}

.return-home-button {
    text-align: center;
    font-weight: bold;
    color: #558eff !important;
    font-size: large;
    text-decoration: underline;
}

.return-home-button:hover {
    color: #89b0fd !important;
}

.emailLabel {
    margin-bottom: 0.8em;
}

.card.subtitle {
    height: 1.25rem;
}

.header > h1 {
    color: #ffffff;
}

.login-card {
    width: 50%;
    margin: 0 auto;
}

.dropbtn,
.dropbtn-landingpage {
    padding: 6px 16px 6px 16px;
    font-size: 16px;
    border: none;
    margin-left: 2px;
    margin-top: 2px;
    border-radius: 0.25rem;
}

.dropbtn {
    background-color: white;
    border: 2px solid #498b98 !important;
    color: #498b98;
}

.dropbtn-landingpage {
    background-color: transparent;
    border: 2px solid #f1f1f1 !important;
    color: #f1f1f1;
}

.dropdown {
    position: relative;
    display: inline-block;
    max-width: fit-content;
      position: absolute;
    top: 0px;
}

.dropdown-content {
    font-size: 15px;
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: fit-content;
    white-space: nowrap;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 2px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropbtn-landingpage:focus  {
    outline: 5px auto #ddd;
}
.dropbtn:focus {
    outline: 5px auto #407580;
}

.dropdown:hover .dropbtn {
    background-color: #407580;
    color: white;
}

.dropdown:hover .dropbtn-landingpage {
    background-color: #ddd;
    border: 2px solid #ddd !important;
    color: black;
}

.dropdown:hover .dropbtn,
.dropdown:hover .dropbtn-landingpage {
    border-radius: 0rem;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
}

.dropdown-content a:first-of-type:hover {
    border-top-right-radius: 0.25rem;
}

.dropdown-content a:last-of-type:hover {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.btn-light {
    background-color: #efefef;
}

.btn-light.animating {
    animation: color-fade 1s;
}

@keyframes color-fade {
    0% {
        background-color: var(--danger);
        color: #efefef;
        border: 1px solid #212529;
    }
    100% {
        background-color: #efefef;
        color: #212529;
        border: 0;
    }
}
